import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Flex from "../components/flex"
import CaseStudyCard from "../components/caseStudyCard"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allCaseStudiesListYaml {
        edges {
          node {
            project
            type
            link
            image {
              childImageSharp {
                fluid(maxWidth: 275) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Home" />
      <Flex
        mt={5}
        flexDirection="column"
        alignItems="center"
      >
        <h1>Hello, User! I'm a UX Designer.</h1>
        <h2>I use design as a mechanism for solving problems, not just as decoration</h2>
        <p style={{ textAlign: 'center', maxWidth: '600px' }}>
          I thrive on using data from research and testing to discover user pain points, wants, and needs in order to design solutions for them. I convey solutions through interaction flows, wireframes, interactive prototypes, low fidelity mockups and high fidelity designs.
        </p>
      </Flex>
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="center"
        my={4}
      >
        <Flex
          flexWrap="wrap"
          maxWidth="900px"
          alignItems="center"
          justifyContent="center"
        >
          {
            data.allCaseStudiesListYaml.edges.map(({ node }) => (
              <CaseStudyCard
                key={node.project}
                {...node}
              />
            ))
          }
        </Flex>
      </Flex>
    </Layout>
  );
}

export default IndexPage
